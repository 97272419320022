const Images = {
  evicon: require('../assets/ev-icon.jpg'),
  ppicon: require('../assets/pp-icon.jpg'),
  picon: require('../assets/p-icon.jpg'),
  pedy: require('../assets/pedy.jpg'),
  partner: require('../assets/partner.jpg'),
  evcharge: require('../assets/evcharge.jpg'),
  app: require('../assets/app.svg')
}

export default Images
