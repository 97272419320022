import React, { Component } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { isMobile as m } from 'react-device-detect'
import ReactSVG from 'react-svg'
import siteLogo from '../assets/logo.svg'
import { camelCase } from 'lodash'

class Header extends Component {
  _nav (arg) {
    const link = v => camelCase(v.toLowerCase())
    this.props.scrollTo(link(arg))
  }

  goToIndex (index) {
    this.props.goToIndex(index)
    this.setState({ color: index === 1 ? 'dark' : 'white' })
  }

  render () {
    return (
      <div>
        <Navbar expand='lg' className='justify-content-between' style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
          <Navbar.Brand>
            <a href='/' >
              <ReactSVG src={siteLogo} svgStyle={{ width: m ? '60px' : '100px' }} />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle ref={(ref) => { this._toggle = ref }} />

          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='ml-auto nav-links bold mr-nav'>
              <Nav.Link onClick={() => this.goToIndex(0)} >Home</Nav.Link>
              <Nav.Link onClick={() => this.goToIndex(2)} >Projects</Nav.Link>
              <Nav.Link onClick={() => this.goToIndex(3)} >Get in touch</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}
export default Header
