import React, { Component } from 'react'
import Header from '../components/header'
import ContactForm from '../components/contactForm'
import Content from './sections/content'
import Banner from './sections/banner'
import Help from './sections/help'
import { FullPage, Slide } from 'react-full-page'

const controlsProps = {
  style: {
    left: '50%',
    paddingTop: '10px',
    position: 'fixed',
    transform: 'translateX(-50%)'
  }
}

class Main extends Component {
  constructor (props) {
    super(props)
    this.state = {
      current: 0,
      menuColor: 'white',
      portfolios: null
    }
    this.fullPage = React.createRef()
  }

  _goToSlide (index) {
    this.setState({ current: index }, () => this.fullPage.current.scrollToSlide(index))
  }

  render () {
    return (<div fluid='lg'>
      <Header index={this.state.current} goToIndex={(i) => this._goToSlide(i)} />
      <FullPage ref={this.fullPage} duration={500} controlsProps={controlsProps}>
        <Slide style={{ background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Banner />
        </Slide>
        <Slide style={{ background: '#ff8700', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Help />
        </Slide>
        <Slide style={{ background: '#fdfdfd', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
          <Content index={this.state.current} />
        </Slide>
        <Slide style={{ background: '#fdfdfd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ContactForm />
        </Slide>
      </FullPage>
    </div>
    )
  }
}
export default Main
