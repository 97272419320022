import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import Colors from '../../utils/colors'
import { Container } from 'react-bootstrap'

const styles = {
  title: {
    position: 'relative',
    zIndex: 0,
    marginTop: -150,
    color: '#333'
  },
  bannerImage: {
    position: 'relative',
    width: '60%',
    margin: 'auto',
    bottom: -480
  },
  buttonGroup: {
    marginTop: 20
  },
  banner: {
    paddingTop: 300,
    height: '700px',
    backgroundColor: Colors.branding
  },
  icon: {
    paddingRight: 10,
    marginLeft: 0
  },
  h2: {
    fontWeight: 200,
    fontSize: 35
  }
}

class Banner extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false
    }

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  render () {
    return (
      <Container >
        <div style={styles.title}>
          <Fade top>
            <h1 className={'bannerH1'}
            > {'Sciphergfx'}</h1>
          </Fade>
          <Fade bottom>
            <h1 className={'bannerH2'}> {'Creative Solutions'}</h1>
          </Fade>
          <div className='sub'>
            <p>React, React Native, UX / UI AND IOT Experts</p>
          </div>
        </div>
      </Container>

    )
  }
}
export default Banner
