import React, { Component } from 'react'
import Typist from 'react-typist'

export default class Help extends Component {
  render () {
    return (
      <div className={'section'}>
        <Typist className={'bannerH3'} stdTypingDelay={0} cursor={{ show: false, hideWhenDone: true }}
        > {'We help  startups launch projects on time.'}
        </Typist>
      </div>
    )
  }
}
