import React, { useEffect, useState } from 'reactn'
import { motion } from 'framer-motion'
import { isMobile as m } from 'react-device-detect'
import { Container } from 'react-bootstrap'
import Images from '../../utils/images'
import { View } from '../../utils/styles'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'
import 'react-tabs/style/react-tabs.css'

const init = [
  { title: 'Pedy',
    type: 'app',
    description: 'The Pedy massage app connects you with expert massage therapists in your area. Avoid the traffic and save time with Pedy –Relax when you want, where you want, by booking a massage today!',
    icon: Images.picon,
    image: Images.pedy,
    link: 'https://play.google.com/store/apps/details?id=com.pedy.sgfx&hl=en' },
  { title: 'Pedy Partner',
    type: 'app',
    description: 'Pedy Partner app for therapists',
    icon: Images.ppicon,
    image: Images.partner,
    link: 'https://play.google.com/store/apps/details?id=com.pedypartner.sgfx&hl=en' },
  { title: 'evCharge',
    type: 'app',
    description: 'evCharge helps electric vehicle drivers find the nearest charging stations around them.',
    icon: Images.evicon,
    image: Images.evcharge,
    link: 'https://play.google.com/store/apps/details?id=com.sciphergfx.evcharge&hl=en' }
]
const variants = {
  hidden: { scale: 0.6 },
  visible: { scale: 1 }
}

function Content (props) {
  const [index, setIndex] = useState(0)

  useEffect(() => {
  }, [index])

  return (
    <div className='flex'>

      <Container>
        <Slider >
          {init.map((value, index) => <div key={index}>
            <Container>
              <View flex ml={10} mr={10}>
                <View mt={0} m={50}>
                  <View className='inline'>
                    <motion.div initial='hidden' animate='visible' variants={variants} transition={{ type: 'spring', stiffness: 260, damping: 20 }} >
                      <img src={value.icon} width='70' />
                    </motion.div>
                    <h4 style={{ fontWeight: 300, margin: '10px' }}>{value.title}</h4>
                  </View>
                  <p style={{ fontWeight: 300, marginTop: 20 }}>{value.description}</p>
                  <a href={value.link} target='_blank' >Download on Play store</a>
                </View>

                {!m && <View m={30}>
                  <img src={value.image} width='100%' />
                </View>}
              </View>
            </Container>
          </div>)}
        </Slider>
      </Container>
    </div>
  )
}

export default Content
