import React, { Component } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import Main from './containers/main'

class App extends Component {
  render () {
    return (
      <ParallaxProvider>
        <Main />
      </ParallaxProvider>
    )
  }
}
export default App
