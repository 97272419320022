import React, { Component } from 'react'
class ContactForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      name: '',
      company: '',
      message: '',
      success: null,
      failure: null,
      pending: false
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange (event) {
    this.setState({ email: event.target.value })
  }

  handleNameChange (event) {
    this.setState({ name: event.target.value })
  }

  handleCompanyChange (event) {
    this.setState({ company: event.target.value })
  }

  handleMessageChange (event) {
    this.setState({ message: event.target.value })
  }

  handleSubmit (event) {
    // event.preventDefault()

    const { email, name, message, company } = this.state

    this.setState({ pending: true })

    const msg = {
      text: message,
      name,
      company,
      from_email: email,
      from_name: 'Contact Form',
      to:
        {
          email: 'sheyie2008@gmail.com',
          type: 'to'
        },
      headers: {
        'Reply-To': email
      }
    }

    this.setState({ success: true, pending: false })

    this.setState({
      email: '',
      subject: '',
      description: ''
    })
  }

  _resetForm () {
    this.setState({
      success: null,
      failure: null,
      pending: false
    })
  }

  _renderForm () {
    const formStyles = {
      height: 'auto',
      margin: '20px auto 10px auto',
      borderRadius: '10px'
    }

    const submitBtnStyles = {
      background: '#ff8700',
      borderRadius: '15px',
      border: 'none',
      width: '100%',
      height: '50px',
      color: 'white'
    }

    const inputStyles = {
      fontSize: '0.8em',
      borderRadius: '15px',
      height: 'auto',
      width: '100%',
      outline: 'none',
      backgroundColor: '#cccccc',
      borderBottom: 'none',
      borderColor: 'transparent',
      padding: '15px 10px',
      margin: '10px 0px',
      color: 'grey'
    }

    const textareaStyles = {
      fontSize: '0.8em',
      minHeight: '80px',
      width: '100%',
      backgroundColor: '#cccccc',
      padding: '8px 10px',
      outline: 'none',
      marginTop: '10px',
      marginBottom: '15px',
      border: 'none',
      borderRadius: '15px',
      color: 'grey',
      resize: 'none'
    }

    return (
      <div className='center'>
        <h3>Get in touch</h3>
        <p>Feel free to reach out to us and we’ll promptly get in touch with you.</p>
        <form
          action='https://formspree.io/info@sciphergfx.com'
          netlify-honeypot='bot-field'
          method='POST'
          style={formStyles}
          name='contact'
          className='contact-form'
          id='contact-us'
          netlify
        >

          <div>
            <input
              disabled={this.state.pending}
              type='text'
              name='name'
              value={this.state.name}
              onChange={this.handleNameChange}
              placeholder='Full Name'
              style={inputStyles}
            />
          </div>
          <div>
            <input
              disabled={this.state.pending}
              type='text'
              name='email'
              value={this.state.email}
              onChange={this.handleEmailChange}
              placeholder='Email Address'
              style={inputStyles}
            />
          </div>
          <div>
            <input
              disabled={this.state.pending}
              type='text'
              name='company'
              value={this.state.company}
              onChange={this.handleCompanyChange}
              placeholder='Company Name'
              style={inputStyles}
            />
          </div>
          <div>
            <textarea
              disabled={this.state.pending}
              name='message'
              style={textareaStyles}
              value={this.state.message}
              onChange={this.handleMessageChange}
              placeholder='Message'
            />
          </div>
          <div data-netlify-recaptcha />
          <button
            disabled={this.state.email === '' ? true : this.state.pending}
            className='contact-btn'
            style={submitBtnStyles}
            type='submit'
            value='SUBMIT'
          >
          Submit
          </button>
        </form>
      </div>
    )
  }

  render () {
    const messageStyles = {
      height: '400px',
      width: '400px',
      margin: 'auto',
      paddingTop: '180px',
      color: 'grey',
      backgroundColor: 'transparent',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: '1.2em'
    }

    const resetStyles = {
      cursor: 'pointer'
    }

    if (this.state.success) {
      return <div style={messageStyles}>Thank you for getting in touch!</div>
    } else if (this.state.failure) {
      return (
        <div style={messageStyles}>
          Something went wrong.<br />Please ensure you have entered correct
          details.{' '}
          <span style={resetStyles} onClick={() => this._resetForm()}>
            <u>Try again</u>
          </span>
        </div>
      )
    } else if (this.state.pending) {
      return <div style={messageStyles}>Sending...</div>
    }
    return this._renderForm()
  }
}

export default ContactForm
